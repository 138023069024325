import React, { useState, useEffect} from 'react'
import SensorGraph from "./SensorGraph"
const images = ["/groupphoto.jpg", "/IMG_5829.jpg", "/IMG_fieldtesting.jpg"];


function Project() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);
  
  return (
    <div className="projectmanager">
      <div className="project">
          <div style={{ position: "relative", textAlign: "center", width:"50%", height:"400px"}}>
              <h2 className="graph-header">Sensor Data</h2>
              <SensorGraph/>
          </div>
          <div className='description'>
              <h2>Sensor Visualization</h2>
              <p>This project is a sensor visualization system that uses an ESP32 microcontroller to collect environmental data and transmit it to an Express.js API. The backend processes the incoming sensor data and serves it to a React frontend, which dynamically updates every five seconds to display live readings. The system ensures real-time monitoring of sensor metrics. By combining embedded systems, web development, and data visualization, this project creates an interactive and responsive interface for observing environmental conditions in real time.</p>
              <h2>Skills</h2>
              <div className="skills">
                  {["Node.js", "React.js", "PostGreSQL", "Express.js", "JavaScript", "HTML", "CSS"].map(item => {return <div className="skill-item" key={item}>{item}</div>;})}
              </div>
          </div>
      </div>
      <div className="project">
          <div style={{ position: "relative", textAlign: "center", width:"50%", height:"400px"}}>
              {/* <h2 className="graph-header">Mesh Network Data</h2> */}
              <img width="500px" src="/groupphoto.jpg"/>
          </div>
          <div className='description'>
              <h2>Boilermake Hackathon (Top 8 Placer)</h2>
              <p>Developed a set of low-cost sensor devices that communicated information over a WiFi mesh. The benefit of this sensor mesh is the extended communication range (600-700 ft), making it ideal for farms spanning thousands of acres. We also developed a small algorithm to aid deployment using satellite imagery.</p>
              <h2>Skills</h2>
              <div className="skills">
                  {["Arduino IDE", "React.js", "Embedded Software", "Soldering"].map(item => {return <div className="skill-item" key={item}>{item}</div>;})}
              </div>
          </div>
      </div>
    </div>
  )
}

export default Project