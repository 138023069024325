import React from 'react'
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { MdHome, MdEmail} from "react-icons/md";

function Navbar() {
  return (
    <ul className="navbar">
        <h1>Rubow</h1>
        <li><MdHome className="react-icon"/></li>
        <div className="navbar-right">
            <li><a href="mailto:joshrubow@outlook.com"><MdEmail className="react-icon"/></a></li>
            <li><a href="https://www.github.com/jrubow" target="_blank"><FaGithub className="react-icon"/></a></li>
            <li><a href="https://www.linkedin.com/in/josh-rubow/" target="_blank"><FaLinkedin className="react-icon"/></a></li>
        </div>
    </ul>
  )
}

export default Navbar