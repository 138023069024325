import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';

const SensorGraph = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/api/sensors/dht11');
                const mergedDataObj = response.data.filter(item => item.sensorId === "esp32-001" && (item.type === "teF" || item.type === "hum")).reduce((acc, item) => {
                            const formattedTime = new Date(item.time).toISOString().slice(0, 19) + "Z";
                            if (!acc[formattedTime]) {
                            acc[formattedTime] = { time: formattedTime };
                            }
                            if (item.type === "teF") {
                            acc[formattedTime].temperature = item.value;
                            } else if (item.type === "hum") {
                            acc[formattedTime].humidity = item.value;
                            }

                            return acc;
                        }, {});

                        // Convert the grouped object to an array
                        const newData = Object.values(mergedDataObj).filter(entry => 
                            entry.temperature !== undefined && entry.humidity !== undefined
                          );
                setData(newData.slice(-25));
                console.log(newData.slice(-25))
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        };

        fetchData();

        const interval = setInterval(fetchData, 3000);

        return () => clearInterval(interval);
    }, []);



    return (
        <ResponsiveContainer>
            <LineChart
                data={data}
                margin={{
                    top: 5, right: 70, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis/>
               <Tooltip />
                <Legend />
                <Line 
                    type="monotone" 
                    dataKey="temperature" 
                    stroke="#00FF00" 
                    activeDot={{ r: 8, fill: "#00FF00" }} 
                    dot={{ fill: "#00FF00" }} 
                />
                <Line 
                    type="monotone" 
                    dataKey="humidity" 
                    stroke="#0000FF" 
                    activeDot={{ r: 8, fill: "#0000FF" }} 
                    dot={{ fill: "#0000FF" }} 
                />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default SensorGraph;
