import SensorGraph from "./components/SensorGraph";
import Navbar from "./components/Navbar"
import Project from "./components/Project"

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Project/>
    </div>

  );
}

export default App;